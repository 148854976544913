<template>
  <header class="mt-24">
    <h1>GSAP Rocks!</h1>
    <div class="menu-icon"></div>
    <ul class="menuDropdownNr1">
      <li class="menu-item ">Home</li>
      <li class="menu-item">About</li>
      <li class="menu-item">Contact</li>
      <li class="menu-item">Rock on GSAP</li>
    </ul>
  </header>

</template>

<script>
import {gsap} from "gsap";


export default {
  name: "Button1",



  mounted() {

    const menuUp = "polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)";
    const menuDown = "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)";

    let menuOpen = false;

    const tl = gsap.timeline({
      paused: true,
      defaults: { duration: 0.3, ease: "power1.inOut" }
    });

    tl.fromTo(".menu-icon", { rotation: 0 }, { rotation: 180 }, 0)
        .fromTo(
            ".menuDropdownNr1",
            { clipPath: menuUp, visibility: "hidden" },
            { clipPath: menuDown, visibility: "visible" },
            0
        )
        .fromTo(
            ".menu-item",
            { opacity: 0, y: "0.5em", stagger: 0.1 },
            { opacity: 1, y: "0em", stagger: 0.1 }
        );

    document.querySelector(".menu-icon").addEventListener("click", () => {
      if (!menuOpen) {
        tl.play();
        menuOpen = true;
      } else {
        tl.reverse();
        menuOpen = false;
      }
    });

  }
}
</script>

<style scoped lang="scss">
@import url("https://fonts.googleapis.com/css?family=Signika+Negative:300,400&display=swap");



header {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  top: 0;
  width: 100%;
  height: 8rem;
  padding: 0 2rem;
  background: #222;
  font-family: "Signika Negative", sans-serif;
  font-weight: 300;

  color: white;
}

.menu-icon {
  position: relative;
  cursor: pointer;
  height: 1.5rem;
  width: 2rem;
}
.menu-icon::before,
.menu-icon::after {
  content: "";
  position: absolute;
  height: 1.5rem;
  width: 0.2em;
  right: 0;
  background-color: white;
  border-radius: 0.2em;
}

.menu-icon::before {
  transform: translateX(-1.45rem) rotate(-45deg);
}

.menu-icon::after {
  transform: translateX(-0.5rem) rotate(45deg);
}

.menuDropdownNr1 {
  position: absolute;
  top: 100%;
  right: 0;
  width: 10em;
  padding: 1em;
  font-size:3rem;
  background: #444;
  list-style: none;
  visibility: hidden;
}

.menuDropdownNr1 li + li {
  margin-top: 1em;
  color: white;
}

.menu-item {
  cursor: pointer;
}

.menu-item:hover {
  color: #ccc;
}


</style>
