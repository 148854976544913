<template>
  <h1 class="text-center">Dropdowns</h1>
  <ul>

    <li class=" text-center text-3xl my-2">
      NR. 1
      <DropdownNr1/>
    </li>

  </ul>


</template>

<script>

import DropdownNr1 from "@/components/partials/Dropdowns/DropdownNr1";

export default {
  name: "Dropdowns",

  components: {DropdownNr1}
}
</script>

<style scoped>

</style>
